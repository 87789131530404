import React from "react";
import PageWrapper from "../components/PageWrapper";

const Terms = () => {
    let pdf_url = "/assets/agreement ectoaffiliate.pdf"
    return (
        <PageWrapper
            headerConfig={{
                bgClass: "d-none",
            }}
            footerConfig={{
                bgClass: "d-none",
            }}
        >
            <iframe width="100%" height="1200px" src={pdf_url} type="application/pdf"></iframe>
        </PageWrapper>
    )
}

export default Terms;
